import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function Contact() {
  return (
    <Layout>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/contact" />
      </Helmet>

      <h4>Email us at the address below</h4>
      <p>
        <a href="mailto:cheeplus@protonmail.ch" style={{ textShadow: `none` }}>Our Email</a>
      </p>
    </Layout>
  )
}